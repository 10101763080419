.recipe {
  padding: 70px 0;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  display: grid;
  grid-template-areas:
    "title       title"
    "photo       photo"
    "tags        tags"
    "actions     actions"
    "ingredients directions";

  grid-template-columns: 1fr 1fr min-content;

  gap: 10px;
}

.recipe h2 {
  font-size:1.2rem;
  font-weight:700;
}

.recipe-title {
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recipe-actions {
  grid-area: actions;
  border-top: 1px dashed #666;
  border-bottom: 1px dashed #666;
  padding: 12px 0;
}

.recipe-actions ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.recipe-actions li {
  list-style-type: none;
  margin: 5px;
}

.recipe-actions input[type=button] {
  background-color: #e13b2b;
  color: #fff;
  padding: 13px 24px 11px;

  margin-top: 8px;
  padding: 13px 24px 11px;
  border-radius: 3px;
  border: none;
}

.recipe-actions input[type=button]:hover {
	color: #222;
	font-weight: 400;
}

.recipe-photo {
  grid-area: photo;
}

.recipe-tags {
  grid-area: tags;
  margin-bottom: 30px;
}

.recipe-ingredients {
  grid-area: ingredients;
  min-width: 300px;
}

.recipe-directions {
  grid-area: directions
}

.recipe h1 {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}

.recipe-photo img{
  width: 100%
}

.recipe-ingredients h3,
.recipe-directions h3 {
  font-size:1.2rem;
  font-weight:700;
  margin-bottom:20px
}

.recipe-ingredients {
  padding-right: 20px;
}

.recipe-ingredients h3 {
  position: sticky;
  top: 10px;
}

.recipe-ingredients ul {
  background: #f9f9f9;
  list-style: none;
  padding: 30px 30px 30px 0;
  position: sticky;
  top: 50px;
}

.recipe-ingredients li {
  margin-bottom:10px;
  padding-bottom:10px;
  border-bottom:1px dashed #dedede
}

.recipe-directions li {
  position:relative;
  margin-bottom:30px;
  padding-left:20px
}

.recipe-directions li.complete {
  opacity: 0.4;
}

.recipe-directions ol {
  list-style-type:none;
  margin:15px 0 0 34px;
  padding:0;
  counter-reset:li-counter
}

.recipe-directions li:before{
  font-size:16px;
  font-weight:700;
  line-height:32px;
  position:absolute;
  top:3px;
  left:-34px;
  width:34px;
  height:34px;
  text-align:center;
  color:#999;
  background-color:#f4f4f4;
  content:counter(li-counter);
  counter-increment:li-counter;
  cursor:default
}

.recipe-photo {
  max-width: 350px;
  max-height: 225px;
}