.meal-plan {
}

.meal-plan ul {
  margin: 0;
  padding: 0;
}

.meal-plan li {
  list-style-type: none;
}

.meal-plan-item {
  display: grid;
  grid-template-areas:
    "photo title"
    "photo tags";
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  gap: 10px;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dedede;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.meal-plan-item-photo {
  grid-area: photo;
}

.meal-plan-item-photo img {
  width: 125px;
}

.meal-plan-item-title {
  grid-area: title;
  font-weight: 700;
  color: #333;
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.2;
  letter-spacing: .5px;
}

.meal-plan-item-tags {
  grid-area: tags;
}