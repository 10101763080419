.header {
  background-color: black;
  padding: 10px 20px;

  display: grid;
  grid-template-areas:
    "left middle right";
  grid-template-columns: 1fr 1fr 1fr;
}

.header,
.header a {
  color: white;
}

.header-left-section {
  grid-area: left;
  align-self: center;
  justify-self: left;
}

.header-middle-section {
  grid-area: middle;
  align-self: center;
  justify-self: center;
  display: flex;
  gap: 25px;
}

.header-right-section {
  grid-area: right;
  align-self: center;
  justify-self: right;
}

.header-app-title {
  font-weight: 700;
  letter-spacing: 5px;
  font-size: 21px;
  white-space: nowrap;
}

.header-icon {
  font-size: 24px;
}
