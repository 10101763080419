/*
    Name: Steaks & Chops - Bootstrap 4 Recipes Template
    Description: Steaks & Chops is a fully responsive, feature rich and beautifully designed Bootstrap 4 Theme for recipes related websites. 
    Version: 1.1
    Author: Excellent Dynamics
*/

@import url(https://fonts.googleapis.com/css?family=Roboto);
body{
    font-family:'Roboto',sans-serif;
    letter-spacing:.5px;
    color:#666;
    background:#fff;
}
body,html{
    height:100%
}
body.demo{
    background: #222;
}
.featured h4,.page .title h4,.recipe-detail h4{
    letter-spacing:2px;
    font-weight:700
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.8rem 1.8rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.45);
    border-radius: 0;
}
.featured{
    margin-top:70px;
    margin-bottom:50px;
    text-align:center
}
.featured h3{
    font-size:2.5rem;
    font-weight:700;
    margin-bottom:40px;
    color:#333
}
.featured h4{
    font-size:1rem;
    text-transform:uppercase;
    color:#cacaca
}
.top{
    padding:80px 0;
    background-color:#222;
}
.box.grid.recipes,.top .box{
    background-color:#fff;
    box-shadow:0 6px 15px rgba(36,37,38,.08)
}
.top h5{
    font-size:1.2rem;
    margin-bottom:30px;
    color:#dadada
}
.top .box{
    padding:25px
}
.top .box h3{
    font-size:1.2rem;
    font-weight:700;
    padding-left:120px
}
.top .box h3 a{
    color:#333
}
.top .box p{
    line-height:22px;
    margin-bottom:0;
    padding-left:120px
}
.top .box img{
    float:left;
    width:100px;
    height:100px;
    border-radius:6px
}
.list{
    margin-top:80px
}
.list h5{
    margin-bottom:40px;
    padding-bottom:20px;
    border-bottom:1px dashed #222
}
.list .btn-load{
    padding:15px 30px;
    color:#666;
    border:1px solid #222;
    border-radius:2px;
    background: #222;
}
.list .btn-load:hover{
    color: #fff;
}
.box.grid.recipes{
    margin-bottom:40px;
    padding-bottom:30px;
    text-align:center;
    border:1px solid #dedede
}
.box.grid.recipes .by{
    padding:30px 45px;
    text-align:left;
    color:#fff;
    background-color:#222;
    font-size: 18px;
}
.box.grid.recipes h2{
    font-size:1.3rem;
    font-weight:700;
    text-transform:capitalize
}
.box.grid.recipes h2 a{
    color:#333
}
.box.grid.recipes img{
    width: 100%;
    margin-bottom:30px
}
.box.list.recipes{
    margin-top:0;
    margin-bottom:30px;
    padding-bottom:20px;
    border:1px solid #dedede;
    background-color:#fff;
    box-shadow:0 6px 15px rgba(36,37,38,.08)
}

.box.list.recipes img{
    float:left;
    width:100px;
    height:100px;
    margin-left:20px
}
.page.contact input,.search .form-group .form-control,.search .form-group input,.search .select2-container .select2-selection--single{
    height:45px
}
.box.list.recipes h2{
    font-size:1.2rem;
    padding-left:140px
}
.box.list.recipes h2 a{
    font-weight:700;
    color:#333
}

.box.list.recipes .by{
    margin-bottom:20px;
    padding:10px 15px;
    text-align:left;
    color:#fff;
    background-color:#e13b2b
}
.box.list.recipes .by{
    padding:30px 45px;
    text-align:left;
    color:#fff;
    background-color:#222;
    font-size: 18px;
}
.page{
    margin-top:70px;
    margin-bottom:50px
}
.page .title h1{
    font-size:2.5rem;
    font-weight:700;
    margin-bottom:40px;
    color:#333
}
.page .title h4{
    font-size:1rem;
    text-transform:uppercase;
    color:#cacaca
}
.page .content .aligncenter,.page .content div.aligncenter{
    display:block;
    margin:5px auto;
    text-align:center
}
.page .content .alignright{
    float:right;
    margin:5px 0 20px 20px
}
.page .content .alignleft{
    float:left;
    margin:5px 20px 20px 0
}
.page .content .caption-text{
    font-size:12px;
    font-style:italic;
    text-align:center
}
.page.contact{
    padding-bottom:40px
}
.page.contact .btn{
    color:#fff;
    padding: 13px 24px 11px;
    background-color:#e13b2b;
}
.page.contact .btn:hover{
	color: #222;
	font-weight: 400;
}
.search{
    padding:60px 0;
    background-color:#f8f8f8
}
.search h2{
    font-size:1.2rem;
    margin-bottom:20px;
    padding-bottom:10px;
    color:#525252;
    border-bottom:1px dashed #dedede
}
.search .form-group{
    position:relative
}
.search .form-group label{
    display:block
}
.search .form-group .btn{
    position:absolute;
    top:0;
    right:0;
    height:45px;
    color:#fff;
    padding: 10px 25px;
    border-radius:0 3px 3px 0;
    background-color:#e13b2b;
}
.search .form-group .btn:hover{
	color: #222;
	font-weight: 400;
}
.search .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height:45px;
    color:#444
}
.search .select2-container--default .select2-selection--single .select2-selection__arrow{
    height:40px
}
.search .select2-container .select2-selection--multiple,.submit .content .select2-container .select2-selection--single,.submit .content input{
    height:45px
}
.search .select2-container--default .select2-selection--multiple .select2-selection__choice{
    margin-top:8px
}
.search .select2-container .select2-search--inline .select2-search__field{
    margin-top:0
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color:#e13b2b!important
}
.select2{
    width:100%!important
}
.submit .title{
    padding:30px 0;
    background-color:#f8f8f8
}
.submit .title h2{
    font-size:1.3rem;
    font-weight:700;
    margin-bottom:0
}
.submit .content{
    padding:40px 0
}
.submit .content .form-group{
    margin-bottom:40px
}
.submit .content .form-group hr{
    border-top:1px dashed #dedede
}
.submit .content .form-group .btn-dark{
    background-color:#363636;
    color:#fff
}
.submit .content .btn-submit{
    padding:15px;
    color:#fff;
}
.submit .content .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height:45px;
    color:#444
}
.submit .content .select2-container--default .select2-selection--single .select2-selection__arrow{
    height:40px
}
.submit .content .box{
    background-color:#f8f8f8;
    margin-bottom:15px;
    padding:20px;
    text-align:center
}
.submit .content .box:hover{
    cursor:pointer
}
.submit .content .box .fa-arrows{
    padding-top:13px
}
.submit .content .box .fa-times-circle-o{
    font-size:20px;
    padding-top:13px
}
.submit .content .box .fa-times-circle-o:hover{
    color:#e13b2b
}

footer{
    margin-top:90px;
    padding:90px 0;
    background-color:#222;
}
footer h5{
    margin-bottom:30px;
    padding-bottom:20px;
    color:#fff;
    border-bottom:1px dashed #484848
}
footer p,footer ul li a{
    color:#858585;
    transition: all 0.3s ease;
}
footer ul li a:hover{
    color: #fff;
    transition: all 0.3s ease;
}
footer ul{
    padding-left:0
}
footer ul li{
    list-style:none
}
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

footer .btn{
    margin-top:8px;
    padding:13px 24px 11px;
    color:#fff;
    border-radius:3px;
    background-color:#e13b2b;
}
footer .btn:hover{color: #222;font-weight: bold;}
.copyright{
    font-size:1rem;
    padding:20px 0;
    background-color:#fff;
}
.copyright a{
    color:#858585
}
.copyright i{
    color:#f44336
}
.demo h1{
    font-size:2rem;
    font-weight:700;
    color:#a7a7a7;
}
.demo p{
    margin-bottom:50px
}
.demo .box{
    box-shadow:0 0 15px rgba(0,0,0,.1);
    border:1px solid #dedede;
    margin-bottom:30px
}
.demo .box:hover{
    opacity:.9
}
.demo .box img{
    width:100%
}
.demo .box .title{
    background-color:#222;
    color:#fff;
    padding:12px 10px;
    text-align:center;
    font-size:1rem
}
:foucs{
	outline: none;
}

@media only screen and (max-width:768px){
    .top .box{
        margin-bottom:30px
    }
    .top .box img{
        width:60px;
        height:60px
    }
    .top .box h3,.top .box p{
        padding-left:80px
    }
    .search .form-group .btn{
        position:static;
        margin-top:20px;
        border-radius:3px;
        width:100%
    }
}
@media only screen and (max-width:480px){
    .featured{
        margin-bottom:20px
    }
    .featured h3{
        font-size:1.6rem
    }
    .top{
        padding:40px 0
    }
    .top .box{
        margin-bottom:30px
    }
    .list{
        margin-top:40px
    }
    .box.list.recipes img{
        width:60px;
        height:60px
    }
    .submit .content input{
        width:100%
    }
    .recipe-detail{
        padding:60px 0
    }
    .recipe-detail h1{
        font-size:1.8rem
    }
    .recipe-detail .col-lg-4{
        margin-bottom:10px
    }
    .recipe-detail .nutrition-facts{
        margin-top:30px
    }
    .recipe-detail .nutrition-facts div{
        width:50%;
        margin-bottom:10px
    }
}

/* My customization */
.btn {
    width: 100%;
}